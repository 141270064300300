[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
.st-sort-ascent:before {
  content: '\25B2';
}
.st-sort-descent:before {
  content: '\25BC';
}
.st-sort-click {
  cursor: pointer;
}
.red {
  background: #d74b4b;
  color: white;
  border: solid 1px #d74b4b;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
body,
html {
  font-family: 'Lato';
  height: 100%;
  font-size: 14px;
}
#header-container {
  background: #2f4354;
  color: #fff;
  font-size: 22px;
  padding: 10px 0;
}
.navbar-collapse {
  padding-left: 0;
}
.content-title {
  position: relative;
  display: block;
  margin-bottom: 1.35em;
  border-bottom: 1px solid #e3e3e3;
}
.content-title span {
  display: inline-block;
  position: relative;
  top: 1px;
  padding-right: 1em;
  padding-bottom: 15px;
  color: #444;
  text-decoration: none;
  border-bottom: 1px solid #6685a4;
  font-weight: bold;
}
#wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  /* Negative indent footer by its height */
  margin: 0 auto -60px;
  /* Pad bottom by footer height */
  padding: 0 0 60px;
}
#page {
  min-height: 100%;
  position: relative;
}
/* Set the fixed height of the footer here */
#footer {
  padding: 10px 0;
  background: #2f4354;
  bottom: 0;
  position: absolute;
  font-size: 12px;
  color: #fff;
  width: 100%;
  margin-top: 20px;
}
/* !Dropdown */
/* ----------------------------------------------- */
.btn-primary .caret,
.btn-secondary .caret,
.btn-tertiary .caret,
.btn-facebook .caret,
.btn-twitter .caret {
  border-top-color: #fff;
}
.dropup .btn-primary .caret,
.dropup .btn-secondary .caret,
.dropup .btn-tertiary .caret,
.dropup .btn-facebook .caret,
.dropup .btn-twitter .caret {
  border-bottom-color: #fff;
}
.dropdown-menu > li {
  margin-right: .5em;
  margin-left: .5em;
}
.dropdown-menu > li > a {
  padding: 6px 12px;
  font-size: 13px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dropdown-header {
  padding-right: 12px;
  padding-left: 12px;
  font-size: 11px;
  text-transform: uppercase;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #fff;
  background-color: #d74b4b;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #444;
  background-color: #dbdbdb;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  display: none;
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}
.navbar .dropdown-submenu > .dropdown-menu,
.mainnav .dropdown-submenu > .dropdown-menu {
  display: block;
  margin-top: -6px;
  margin-left: 20px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dropdown-submenu:hover > .dropdown-menu:before,
.dropdown-submenu:hover > .dropdown-menu:after {
  display: none;
}
@media (min-width: 768px) {
  .navbar .dropdown-submenu > .dropdown-menu,
  .mainnav .dropdown-submenu > .dropdown-menu {
    display: none;
    margin-left: -1px;
  }
  .navbar .dropdown-submenu:hover > .dropdown-menu,
  .mainnav .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }
  .dropdown-submenu > a:after {
    content: " ";
    display: block;
    float: right;
    width: 0;
    height: 0;
    margin-top: 5px;
    margin-right: -10px;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #cccccc;
  }
  .dropdown-submenu:hover > a:after {
    border-left-color: #d74b4b;
  }
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
/* !Navbar */
/* ----------------------------------------------- */
.navbar-inverse {
  margin-bottom: 0;
  background-color: #2f4354;
  background-image: -webkit-linear-gradient(top, #425d75 0%, #2f4354 100%);
  background-image: linear-gradient(to bottom, #425d75 0%, #2f4354 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff425d75', endColorstr='#ff2f4354', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border: none;
  border-bottom: 1px solid #141c23;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 1001;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #141c23;
}
.navbar-collapse.in {
  max-height: none;
  overflow-y: visible;
}
.navbar-inverse .navbar-brand {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-inverse .navbar-brand:hover {
  color: #fff;
  background-color: #2c3e4e;
}
.navbar-inverse .navbar-brand-img {
  height: 60px;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-inverse .navbar-toggle {
  padding: 0 18px;
  margin: 0;
  height: 60px;
  color: #fff;
  color: #6f91ae;
  font-size: 17px;
  text-align: center;
  line-height: 60px;
  border: none;
  outline: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.navbar-inverse .navbar-toggle:active,
.navbar-inverse .navbar-toggle:focus {
  background-color: transparent;
}
.navbar-inverse .navbar-toggle:hover {
  color: #547795;
  background-color: #3b5368;
}
.navbar-inverse .navbar-toggle.is-open {
  color: #fff;
  background-color: #3b5368;
}
.navbar-inverse .navbar-nav > li > a {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.navbar-inverse .navbar-nav > li > a:hover {
  background-color: #2c3e4e;
}
.navbar-inverse .navbar-nav > li.active > a,
.navbar-inverse .navbar-nav > li.active > a:hover,
.navbar-inverse .navbar-nav > li.active > a:active,
.navbar-inverse .navbar-nav > li.active > a:focus {
  background-color: #2c3e4e;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #2c3e4e;
}
.navbar-inverse .navbar-nav > .navbar-profile > a {
  height: 60px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 60px;
}
.navbar-profile-avatar {
  position: relative;
  top: -1px;
  width: 30px;
  margin-right: .65em;
  border: 2px solid #fff;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.navbar-profile-label {
  display: inline;
  font-size: 13px;
}
.navbar-profile-label-visible {
  display: inline !important;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
  }
}
@media (min-width: 768px) {
  .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-profile-label {
    display: none;
  }
  .navbar-inverse .navbar-nav > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 13px;
  }
}
.navbar-visible-collapsed {
  display: inline;
  color: #fff;
}
@media (min-width: 768px) {
  .navbar-visible-collapsed {
    display: none;
  }
  .navbar-collapse .navbar-nav.navbar-left:first-child {
    margin-left: 0;
  }
  .navbar .dropdown-menu:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    position: absolute;
    top: -7px;
    left: 9px;
  }
  .navbar-right .dropdown-menu:before {
    right: 9px;
    left: auto;
  }
}
.mainnav {
  position: relative;
  /*margin-bottom: 40px;*/
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}
.mainnav-toggle {
  display: block;
  padding: 12px 0;
  color: #d74b4b;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}
.mainnav-toggle:hover {
  text-decoration: none;
}
.mainnav-toggle i {
  color: #d74b4b;
}
.mainnav-toggle:hover {
  color: #d13232;
}
.mainnav-form {
  position: relative;
}
.mainnav-form-btn {
  position: absolute;
  top: 17px;
  right: 2px;
  color: #d74b4b;
  background-color: transparent;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.mainnav-form-btn:hover {
  color: #9a2222;
}
.mainnav-menu {
  padding: 0;
  margin: 0 -15px;
  list-style-type: none;
}
.mainnav-menu > li {
  position: relative;
}
.mainnav-menu > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: #999;
  font-weight: 600;
}
.mainnav-menu > li > a:hover {
  text-decoration: none;
  background-color: #eee;
}
.mainnav-menu > li > a,
.mainnav-menu > li > a:hover,
.mainnav-menu > li > a:focus {
  text-decoration: none;
}
.mainnav-menu > li.open > a,
.mainnav-menu > li.open > a:hover,
.mainnav-menu > li.open > a:focus {
  color: #555;
  border-bottom-color: transparent;
}
.mainnav-menu .mainnav-caret:before {
  display: inline-block;
  position: relative;
  top: -1px;
  margin-left: .25em;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  content: "\f0d7";
  -webkit-font-smoothing: antialiased;
}
.mainnav-menu > li.active > a,
.mainnav-menu > li.active > a:hover,
.mainnav-menu > li.active > a:focus {
  color: #d74b4b;
  border-bottom-color: #d74b4b;
}
.mainnav:after {
  content: '';
  position: absolute;
  top: 100%;
  z-index: -1;
  width: 100%;
  height: 50px;
  margin-top: 2px;
  background-image: -webkit-linear-gradient(top, #f2f2f2 0%, #ffffff 100%);
  background-image: linear-gradient(to bottom, #f2f2f2 0%, #ffffff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffffffff', GradientType=0);
}
.mainnav .dropdown-menu {
  min-width: 200px;
}
@media (min-width: 768px) {
  .mainnav-toggle {
    display: none;
  }
  .mainnav-collapse {
    width: auto;
    padding-right: 0;
    padding-left: 0;
    border-top: 0;
    box-shadow: none;
  }
  .mainnav-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .mainnav-form {
    margin-top: 20px;
  }
  .mainnav-search-query {
    width: 120px;
  }
  .mainnav-form-btn {
    top: 2px;
  }
  .mainnav-menu {
    margin-left: -15px;
  }
  .mainnav-menu > li {
    float: left;
    margin-right: 0;
  }
  .mainnav-menu > li > a {
    padding: 21px 15px;
    font-size: 14px;
    letter-spacing: -1px;
    border-bottom: 1px solid transparent;
  }
  .mainnav-menu > li > a:hover {
    background-color: #f3f3f3;
  }
  .mainnav-menu > li.active > a {
    border-bottom-color: #d23636;
  }
  .mainnav-menu > li.is-open > a {
    background-color: transparent;
  }
  .mainnav-menu .mainnav-caret {
    font-size: 13px;
  }
  .mainnav-menu > li > .dropdown-menu {
    top: 97%;
    border-color: #ccc;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .mainnav-menu > li > .dropdown-menu:before,
  .mainnav-menu > li > .dropdown-menu:after {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #CCC;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 9px;
  }
  .mainnav-menu > li > .dropdown-menu:after {
    border-left-width: 6px;
    border-right-width: 6px;
    border-bottom-width: 6px;
    border-bottom-color: #fff;
    top: -6px;
    left: 10px;
  }
}
@media (min-width: 992px) {
  .mainnav-search-query {
    width: auto;
  }
  .mainnav-menu > li {
    margin-right: 10px;
  }
  .mainnav-menu > li > a {
    font-size: 20px;
  }
  .mainnav-menu .mainnav-caret {
    top: -2px;
    font-size: 18px;
  }
  .lt-ie9 .mainnav-menu > li > a {
    font-size: 16px;
  }
  .lt-ie9 .mainnav-menu .mainnav-caret {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .mainnav-menu .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .mainnav-menu .dropdown-menu > li > a {
    padding-left: 25px;
  }
  .mainnav-menu > li.is-open .dropdown-menu {
    display: block !important;
  }
  .mainnav-form {
    float: none !important;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
}
.dataTables_wrapper {
  position: relative;
  clear: both;
  margin-bottom: 15px;
}
.dataTables_wrapper::after {
  clear: both;
  display: block;
  content: '';
}
.dataTables_length {
  float: left;
  margin-bottom: 10px;
}
.dataTables_length::after {
  clear: both;
  display: block;
  content: '';
}
.dataTables_length label {
  display: inline-block;
}
.dataTable {
  display: inline-table;
  margin-bottom: 15px;
}
.dataTable:before {
  display: block;
  clear: both;
  content: '';
}
.dataTable thead > tr > th {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 97% center;
}
.dataTables_filter {
  float: right;
  text-align: right;
  margin-bottom: 10px;
}
.dataTables_filter:after {
  display: block;
  clear: both;
  content: '';
}
.dataTables_filter input {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.dataTables_filter input:focus {
  border-color: #d74b4b;
  -moz-box-shadow: 3px 3px 0 rgba(12, 12, 12, 0.05);
  -webkit-box-shadow: 3px 3px 0 rgba(12, 12, 12, 0.05);
  box-shadow: 3px 3px 0 rgba(12, 12, 12, 0.05);
  outline: 0;
}
.dataTables_filter input::-moz-placeholder {
  color: #777;
  opacity: 1;
}
.dataTables_filter input:-ms-input-placeholder {
  color: #777;
}
.dataTables_filter input::-webkit-input-placeholder {
  color: #777;
}
/*.dataTables_filter input:focus {*/
/*border-color: #999;*/
/*-moz-box-shadow: 3px 3px 0 rgba(0,0,0,0.05);*/
/*-webkit-box-shadow: 3px 3px 0 rgba(0,0,0,0.05);*/
/*box-shadow: 3px 3px 0 rgba(0,0,0,0.05);*/
/*}*/
.dataTables_info {
  clear: both;
  float: left;
}
.dataTables_paginate {
  float: right;
  text-align: right;
}
/* Two button pagination - previous / next */
.paginate_button {
  float: left;
  cursor: pointer;
  padding: 6px 15px;
  display: inline-block;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #666;
}
.paginate_button {
  color: #fff;
  background: #d74b4b;
  border: 1px solid #b32828;
}
.paginate_button:hover {
  background: #ac3c3c;
  border-color: #8f2020;
  color: #fff;
  text-decoration: none;
}
.paginate_button.disabled {
  border: 1px solid #ccc;
  background-color: #ddd;
  color: #999;
}
.paginate_button.disabled:hover {
  text-decoration: none;
  color: #999;
  cursor: default;
}
.paginate_button {
  margin-left: 5px;
}
.paging_simple_numbers span {
  display: none;
}
/* Full number pagination */
.paging_full_numbers {
  line-height: 22px;
}
.paging_full_numbers a:active {
  outline: none;
}
.paging_full_numbers a:hover {
  text-decoration: none;
}
.paging_full_numbers a.paginate_button,
.paging_full_numbers a.paginate_active {
  border: 1px solid #ddd;
  padding: 6px 12px;
  margin-right: 5px;
  cursor: pointer;
  color: #636E7B;
  line-height: 21px;
  float: left;
  position: relative;
  background: #fff;
  display: inline-block;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.paging_full_numbers > li:first-child > a,
.paging_full_numbers > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.paging_full_numbers a.paginate_button:hover {
  text-decoration: none !important;
  background-color: #E4E7EA;
}
.paging_full_numbers a.paginate_active {
  background-color: #1CAF9A;
  border-color: #1CAF9A;
  color: #fff;
}
.paging_full_numbers a:focus {
  text-decoration: none;
}
.paging_full_numbers a.last {
  margin-right: 0;
}
.paginate_button_disabled {
  opacity: 0.5;
}
.paginate_button_disabled:hover {
  cursor: default !important;
  background-color: #fff !important;
}
/*
 * Processing indicator
 */
.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 30px;
  margin-left: -125px;
  margin-top: -15px;
  padding: 14px 0 2px 0;
  border: 1px solid #ddd;
  text-align: center;
  color: #999;
  font-size: 14px;
  background-color: white;
}
/*
 * Sorting
 */
.sorting {
  background-image: url('../../images/sort_both.png');
}
.sorting_asc {
  background-image: url('../../images/sort_asc.png');
}
.sorting_desc {
  background-image: url('../../images/sort_desc.png');
}
.sorting_asc_disabled {
  background-image: url('../../images/sort_asc_disabled.png');
}
.sorting_desc_disabled {
  background-image: url('../../images/sort_desc_disabled.png');
}
table.dataTable thead th:active,
table.dataTable thead td:active {
  outline: none;
}
/*
 * Scrolling
 */
.dataTables_scroll {
  clear: both;
}
.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}
.nopadding {
  padding: 0 !important;
}
.table-primary thead,
.panel-primary > .panel-heading {
  background: #d74b4b;
}
.panel-primary,
.panel-primary > .panel-heading {
  border-color: #d74b4b;
}
.panel-secondary > .panel-heading {
  background-color: #428bca;
}
.panel-secondary,
.panel-secondary > .panel-heading {
  border-color: #428bca;
}
.panel-thirdary > .panel-heading {
  background-color: #449d44;
}
.panel-thirdary,
.panel-thirdary > .panel-heading {
  border-color: #449d44;
}
.table-primary {
  border: 1px solid #d74b4b;
}
.table-success thead {
  background: #1CAF9A;
}
.table-success {
  border: 1px solid #1CAF9A;
}
.table-warning thead {
  background: #F0AD4E;
}
.table-danger thead {
  background: #D9534F;
}
.table-info thead {
  background: #5BC0DE;
}
.table-dark thead {
  background: #1D2939;
}
.table.table-primary thead tr th,
.table.table-success thead tr th,
.table.table-danger thead tr th,
.table.table-warning thead tr th,
.table.table-info thead tr th,
.table.table-dark thead tr th {
  color: #fff;
  border-bottom: 0;
}
.table-bordered.table-primary thead tr th,
.table-bordered.table-success thead tr th,
.table-bordered.table-warning thead tr th,
.table-bordered.table-danger thead tr th,
.table-bordered.table-info thead tr th,
.table-bordered.table-dark thead tr th {
  border-color: rgba(255, 255, 255, 0.2);
}
#globalSearch {
  width: 250px;
}
#globalSearchResults {
  position: absolute;
  background: #fff;
  border: 1px solid #d74b4b;
  border-top: 0;
  width: 250px;
  margin-top: -3px;
  padding-top: 5px;
  z-index: 99999999;
  display: none;
}
#globalSearchResults .result {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  cursor: pointer;
}
#globalSearchResults .result:hover {
  background: #efefef;
}
#globalSearchResults .result .icon {
  display: inline-block;
  margin-right: 15px;
}
#globalSearchResults .result .info {
  display: inline-block;
}
#globalSearchResults .result .title {
  font-size: 16px;
  color: #555;
}
#globalSearchResults .result .type {
  color: #626262;
  font-size: 12px;
}
.chart-holder,
.chart-holder-200,
.chart-holder-250 {
  width: 98%;
  margin: 0 auto;
}
.chart-holder {
  height: 300px;
}
.chart-holder-200 {
  height: 200px;
}
.chart-holder-250 {
  height: 250px;
}
.chart-holder-300 {
  height: 300px;
}
.chart-holder-350 {
  height: 350px;
}
.chart-holder-400 {
  height: 400px;
}
.nicedata div {
  border-bottom: 1px solid #ddd;
  padding: 18px 0;
}
.nicedata i {
  font-size: 18px;
  margin-right: 10px;
  color: #fff;
  background: #d74b4b;
  padding: 6px;
  border-radius: 4px;
  font-weight: normal;
}
.nicedata div span:first-child {
  display: inline-block;
  /*text-transform: uppercase;*/
  column-rule: #555;
  font-size: 13px;
}
.nicedata div span.value {
  font-weight: bold;
  font-size: 16px;
  float: right;
  display: inline-block;
}
.smaller-font .nicedata div span.value {
  font-size: 14px;
}
.nicedata div span.statsDataType {
  font-weight: normal;
  float: none;
  font-size: 14px;
}
