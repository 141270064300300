@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon.eot?-ujylxd');
  src: url('/fonts/icomoon.eot?#iefix-ujylxd') format('embedded-opentype'), url('/fonts/icomoon.woff?-ujylxd') format('woff'), url('/fonts/icomoon.ttf?-ujylxd') format('truetype'), url('/fonts/icomoon.svg?-ujylxd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #1D293B;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"].small,
[class*=" icon-"].small {
  font-size: 14px;
}
[class^="icon-"].medium,
[class*=" icon-"].medium {
  font-size: 18px;
}
[class^="icon-"].smedium,
[class*=" icon-"].smedium {
  font-size: 24px;
}
[class^="icon-"].large,
[class*=" icon-"].large {
  font-size: 36px;
}
.icon-home:before {
  content: "\e000";
}
.icon-home2:before {
  content: "\e001";
}
.icon-home3:before {
  content: "\e002";
}
.icon-home4:before {
  content: "\e003";
}
.icon-home5:before {
  content: "\e004";
}
.icon-home6:before {
  content: "\e005";
}
.icon-home7:before {
  content: "\e006";
}
.icon-home8:before {
  content: "\e007";
}
.icon-home9:before {
  content: "\e008";
}
.icon-home10:before {
  content: "\e009";
}
.icon-home11:before {
  content: "\e00a";
}
.icon-office:before {
  content: "\e00b";
}
.icon-newspaper:before {
  content: "\e00c";
}
.icon-pencil:before {
  content: "\e00d";
}
.icon-pencil2:before {
  content: "\e00e";
}
.icon-pencil3:before {
  content: "\e00f";
}
.icon-pencil4:before {
  content: "\e010";
}
.icon-pencil5:before {
  content: "\e011";
}
.icon-pencil6:before {
  content: "\e012";
}
.icon-quill:before {
  content: "\e013";
}
.icon-quill2:before {
  content: "\e014";
}
.icon-quill3:before {
  content: "\e015";
}
.icon-pen:before {
  content: "\e016";
}
.icon-pen2:before {
  content: "\e017";
}
.icon-pen3:before {
  content: "\e018";
}
.icon-pen4:before {
  content: "\e019";
}
.icon-pen5:before {
  content: "\e01a";
}
.icon-marker:before {
  content: "\e01b";
}
.icon-home12:before {
  content: "\e01c";
}
.icon-marker2:before {
  content: "\e01d";
}
.icon-blog:before {
  content: "\e01e";
}
.icon-blog2:before {
  content: "\e01f";
}
.icon-brush:before {
  content: "\e020";
}
.icon-palette:before {
  content: "\e021";
}
.icon-palette2:before {
  content: "\e022";
}
.icon-eyedropper:before {
  content: "\e023";
}
.icon-eyedropper2:before {
  content: "\e024";
}
.icon-droplet:before {
  content: "\e025";
}
.icon-droplet2:before {
  content: "\e026";
}
.icon-droplet3:before {
  content: "\e027";
}
.icon-droplet4:before {
  content: "\e028";
}
.icon-paint-format:before {
  content: "\e029";
}
.icon-paint-format2:before {
  content: "\e02a";
}
.icon-image:before {
  content: "\e02b";
}
.icon-image2:before {
  content: "\e02c";
}
.icon-image3:before {
  content: "\e02d";
}
.icon-images:before {
  content: "\e02e";
}
.icon-image4:before {
  content: "\e02f";
}
.icon-image5:before {
  content: "\e030";
}
.icon-image6:before {
  content: "\e031";
}
.icon-images2:before {
  content: "\e032";
}
.icon-image7:before {
  content: "\e033";
}
.icon-camera:before {
  content: "\e034";
}
.icon-camera2:before {
  content: "\e035";
}
.icon-camera3:before {
  content: "\e036";
}
.icon-camera4:before {
  content: "\e037";
}
.icon-music:before {
  content: "\e038";
}
.icon-music2:before {
  content: "\e039";
}
.icon-music3:before {
  content: "\e03a";
}
.icon-music4:before {
  content: "\e03b";
}
.icon-music5:before {
  content: "\e03c";
}
.icon-music6:before {
  content: "\e03d";
}
.icon-piano:before {
  content: "\e03e";
}
.icon-guitar:before {
  content: "\e03f";
}
.icon-headphones:before {
  content: "\e040";
}
.icon-headphones2:before {
  content: "\e041";
}
.icon-play:before {
  content: "\e042";
}
.icon-play2:before {
  content: "\e043";
}
.icon-movie:before {
  content: "\e044";
}
.icon-movie2:before {
  content: "\e045";
}
.icon-movie3:before {
  content: "\e046";
}
.icon-film:before {
  content: "\e047";
}
.icon-film2:before {
  content: "\e048";
}
.icon-film3:before {
  content: "\e049";
}
.icon-film4:before {
  content: "\e04a";
}
.icon-camera5:before {
  content: "\e04b";
}
.icon-camera6:before {
  content: "\e04c";
}
.icon-camera7:before {
  content: "\e04d";
}
.icon-camera8:before {
  content: "\e04e";
}
.icon-camera9:before {
  content: "\e04f";
}
.icon-dice:before {
  content: "\e050";
}
.icon-gamepad:before {
  content: "\e051";
}
.icon-gamepad2:before {
  content: "\e052";
}
.icon-gamepad3:before {
  content: "\e053";
}
.icon-pacman:before {
  content: "\e054";
}
.icon-spades:before {
  content: "\e055";
}
.icon-clubs:before {
  content: "\e056";
}
.icon-diamonds:before {
  content: "\e057";
}
.icon-king:before {
  content: "\e058";
}
.icon-queen:before {
  content: "\e059";
}
.icon-rock:before {
  content: "\e05a";
}
.icon-bishop:before {
  content: "\e05b";
}
.icon-knight:before {
  content: "\e05c";
}
.icon-pawn:before {
  content: "\e05d";
}
.icon-chess:before {
  content: "\e05e";
}
.icon-bullhorn:before {
  content: "\e05f";
}
.icon-megaphone:before {
  content: "\e060";
}
.icon-new:before {
  content: "\e061";
}
.icon-connection:before {
  content: "\e062";
}
.icon-connection2:before {
  content: "\e063";
}
.icon-podcast:before {
  content: "\e064";
}
.icon-radio:before {
  content: "\e065";
}
.icon-feed:before {
  content: "\e066";
}
.icon-connection3:before {
  content: "\e067";
}
.icon-radio2:before {
  content: "\e068";
}
.icon-podcast2:before {
  content: "\e069";
}
.icon-podcast3:before {
  content: "\e06a";
}
.icon-mic:before {
  content: "\e06b";
}
.icon-mic2:before {
  content: "\e06c";
}
.icon-mic3:before {
  content: "\e06d";
}
.icon-mic4:before {
  content: "\e06e";
}
.icon-mic5:before {
  content: "\e06f";
}
.icon-book:before {
  content: "\e070";
}
.icon-book2:before {
  content: "\e071";
}
.icon-books:before {
  content: "\e072";
}
.icon-reading:before {
  content: "\e073";
}
.icon-library:before {
  content: "\e074";
}
.icon-library2:before {
  content: "\e075";
}
.icon-graduation:before {
  content: "\e076";
}
.icon-file:before {
  content: "\e077";
}
.icon-profile:before {
  content: "\e078";
}
.icon-file2:before {
  content: "\e079";
}
.icon-file3:before {
  content: "\e07a";
}
.icon-file4:before {
  content: "\e07b";
}
.icon-file5:before {
  content: "\e07c";
}
.icon-file6:before {
  content: "\e07d";
}
.icon-files:before {
  content: "\e07e";
}
.icon-file-plus:before {
  content: "\e07f";
}
.icon-file-minus:before {
  content: "\e080";
}
.icon-file-download:before {
  content: "\e081";
}
.icon-file-upload:before {
  content: "\e082";
}
.icon-file-check:before {
  content: "\e083";
}
.icon-file-remove:before {
  content: "\e084";
}
.icon-file7:before {
  content: "\e085";
}
.icon-file8:before {
  content: "\e086";
}
.icon-file-plus2:before {
  content: "\e087";
}
.icon-file-minus2:before {
  content: "\e088";
}
.icon-file-download2:before {
  content: "\e089";
}
.icon-file-upload2:before {
  content: "\e08a";
}
.icon-file-check2:before {
  content: "\e08b";
}
.icon-file-remove2:before {
  content: "\e08c";
}
.icon-file9:before {
  content: "\e08d";
}
.icon-copy:before {
  content: "\e08e";
}
.icon-copy2:before {
  content: "\e08f";
}
.icon-copy3:before {
  content: "\e090";
}
.icon-copy4:before {
  content: "\e091";
}
.icon-paste:before {
  content: "\e092";
}
.icon-paste2:before {
  content: "\e093";
}
.icon-paste3:before {
  content: "\e094";
}
.icon-stack:before {
  content: "\e095";
}
.icon-stack2:before {
  content: "\e096";
}
.icon-stack3:before {
  content: "\e097";
}
.icon-folder:before {
  content: "\e098";
}
.icon-folder-download:before {
  content: "\e099";
}
.icon-folder-upload:before {
  content: "\e09a";
}
.icon-folder-plus:before {
  content: "\e09b";
}
.icon-folder-plus2:before {
  content: "\e09c";
}
.icon-folder-minus:before {
  content: "\e09d";
}
.icon-folder-minus2:before {
  content: "\e09e";
}
.icon-folder8:before {
  content: "\e09f";
}
.icon-folder-remove:before {
  content: "\e0a0";
}
.icon-folder2:before {
  content: "\e0a1";
}
.icon-folder-open:before {
  content: "\e0a2";
}
.icon-folder3:before {
  content: "\e0a3";
}
.icon-folder4:before {
  content: "\e0a4";
}
.icon-folder-plus3:before {
  content: "\e0a5";
}
.icon-folder-minus3:before {
  content: "\e0a6";
}
.icon-folder-plus4:before {
  content: "\e0a7";
}
.icon-folder-remove2:before {
  content: "\e0a8";
}
.icon-folder-download2:before {
  content: "\e0a9";
}
.icon-folder-upload2:before {
  content: "\e0aa";
}
.icon-folder-download3:before {
  content: "\e0ab";
}
.icon-folder-upload3:before {
  content: "\e0ac";
}
.icon-folder5:before {
  content: "\e0ad";
}
.icon-folder-open2:before {
  content: "\e0ae";
}
.icon-folder6:before {
  content: "\e0af";
}
.icon-folder-open3:before {
  content: "\e0b0";
}
.icon-certificate:before {
  content: "\e0b1";
}
.icon-cc:before {
  content: "\e0b2";
}
.icon-tag:before {
  content: "\e0b3";
}
.icon-tag2:before {
  content: "\e0b4";
}
.icon-tag3:before {
  content: "\e0b5";
}
.icon-tag4:before {
  content: "\e0b6";
}
.icon-tag5:before {
  content: "\e0b7";
}
.icon-tag6:before {
  content: "\e0b8";
}
.icon-tag7:before {
  content: "\e0b9";
}
.icon-tags:before {
  content: "\e0ba";
}
.icon-tags2:before {
  content: "\e0bb";
}
.icon-tag8:before {
  content: "\e0bc";
}
.icon-barcode:before {
  content: "\e0bd";
}
.icon-barcode2:before {
  content: "\e0be";
}
.icon-qrcode:before {
  content: "\e0bf";
}
.icon-ticket:before {
  content: "\e0c0";
}
.icon-cart:before {
  content: "\e0c1";
}
.icon-cart2:before {
  content: "\e0c2";
}
.icon-cart3:before {
  content: "\e0c3";
}
.icon-cart4:before {
  content: "\e0c4";
}
.icon-cart5:before {
  content: "\e0c5";
}
.icon-cart6:before {
  content: "\e0c6";
}
.icon-cart7:before {
  content: "\e0c7";
}
.icon-cart-plus:before {
  content: "\e0c8";
}
.icon-cart-minus:before {
  content: "\e0c9";
}
.icon-cart-add:before {
  content: "\e0ca";
}
.icon-cart-remove:before {
  content: "\e0cb";
}
.icon-cart-checkout:before {
  content: "\e0cc";
}
.icon-cart-remove2:before {
  content: "\e0cd";
}
.icon-basket:before {
  content: "\e0ce";
}
.icon-basket2:before {
  content: "\e0cf";
}
.icon-bag:before {
  content: "\e0d0";
}
.icon-bag2:before {
  content: "\e0d1";
}
.icon-bag3:before {
  content: "\e0d2";
}
.icon-coin:before {
  content: "\e0d3";
}
.icon-coins:before {
  content: "\e0d4";
}
.icon-credit:before {
  content: "\e0d5";
}
.icon-credit2:before {
  content: "\e0d6";
}
.icon-calculate:before {
  content: "\e0d7";
}
.icon-calculate2:before {
  content: "\e0d8";
}
.icon-support:before {
  content: "\e0d9";
}
.icon-phone:before {
  content: "\e0da";
}
.icon-phone2:before {
  content: "\e0db";
}
.icon-phone3:before {
  content: "\e0dc";
}
.icon-phone4:before {
  content: "\e0dd";
}
.icon-contact-add:before {
  content: "\e0de";
}
.icon-contact-remove:before {
  content: "\e0df";
}
.icon-contact-add2:before {
  content: "\e0e0";
}
.icon-contact-remove2:before {
  content: "\e0e1";
}
.icon-call-incoming:before {
  content: "\e0e2";
}
.icon-call-outgoing:before {
  content: "\e0e3";
}
.icon-phone5:before {
  content: "\e0e4";
}
.icon-phone6:before {
  content: "\e0e5";
}
.icon-phone-hang-up:before {
  content: "\e0e6";
}
.icon-phone-hang-up2:before {
  content: "\e0e7";
}
.icon-address-book:before {
  content: "\e0e8";
}
.icon-address-book2:before {
  content: "\e0e9";
}
.icon-notebook:before {
  content: "\e0ea";
}
.icon-envelope:before {
  content: "\e0eb";
}
.icon-envelope2:before {
  content: "\e0ec";
}
.icon-mail-send:before {
  content: "\e0ed";
}
.icon-envelope-opened:before {
  content: "\e0ee";
}
.icon-envelope3:before {
  content: "\e0ef";
}
.icon-pushpin:before {
  content: "\e0f0";
}
.icon-location:before {
  content: "\e0f1";
}
.icon-location2:before {
  content: "\e0f2";
}
.icon-location3:before {
  content: "\e0f3";
}
.icon-location4:before {
  content: "\e0f4";
}
.icon-location5:before {
  content: "\e0f5";
}
.icon-location6:before {
  content: "\e0f6";
}
.icon-location7:before {
  content: "\e0f7";
}
.icon-compass:before {
  content: "\e0f8";
}
.icon-compass2:before {
  content: "\e0f9";
}
.icon-map:before {
  content: "\e0fa";
}
.icon-map2:before {
  content: "\e0fb";
}
.icon-map3:before {
  content: "\e0fc";
}
.icon-map4:before {
  content: "\e0fd";
}
.icon-direction:before {
  content: "\e0fe";
}
.icon-history:before {
  content: "\e0ff";
}
.icon-history2:before {
  content: "\e100";
}
.icon-clock:before {
  content: "\e101";
}
.icon-clock2:before {
  content: "\e102";
}
.icon-clock3:before {
  content: "\e103";
}
.icon-clock4:before {
  content: "\e104";
}
.icon-watch:before {
  content: "\e105";
}
.icon-clock5:before {
  content: "\e106";
}
.icon-clock6:before {
  content: "\e107";
}
.icon-clock7:before {
  content: "\e108";
}
.icon-alarm:before {
  content: "\e109";
}
.icon-alarm2:before {
  content: "\e10a";
}
.icon-bell:before {
  content: "\e10b";
}
.icon-bell2:before {
  content: "\e10c";
}
.icon-alarm-plus:before {
  content: "\e10d";
}
.icon-alarm-minus:before {
  content: "\e10e";
}
.icon-alarm-check:before {
  content: "\e10f";
}
.icon-alarm-cancel:before {
  content: "\e110";
}
.icon-stopwatch:before {
  content: "\e111";
}
.icon-calendar:before {
  content: "\e112";
}
.icon-calendar2:before {
  content: "\e113";
}
.icon-calendar3:before {
  content: "\e114";
}
.icon-calendar4:before {
  content: "\e115";
}
.icon-calendar5:before {
  content: "\e116";
}
.icon-print:before {
  content: "\e117";
}
.icon-print2:before {
  content: "\e118";
}
.icon-print3:before {
  content: "\e119";
}
.icon-mouse:before {
  content: "\e11a";
}
.icon-mouse2:before {
  content: "\e11b";
}
.icon-mouse3:before {
  content: "\e11c";
}
.icon-mouse4:before {
  content: "\e11d";
}
.icon-keyboard:before {
  content: "\e11e";
}
.icon-keyboard2:before {
  content: "\e11f";
}
.icon-screen:before {
  content: "\e120";
}
.icon-screen2:before {
  content: "\e121";
}
.icon-screen3:before {
  content: "\e122";
}
.icon-screen4:before {
  content: "\e123";
}
.icon-laptop:before {
  content: "\e124";
}
.icon-mobile:before {
  content: "\e125";
}
.icon-mobile2:before {
  content: "\e126";
}
.icon-tablet:before {
  content: "\e127";
}
.icon-mobile3:before {
  content: "\e128";
}
.icon-tv:before {
  content: "\e129";
}
.icon-cabinet:before {
  content: "\e12a";
}
.icon-archive:before {
  content: "\e12b";
}
.icon-drawer:before {
  content: "\e12c";
}
.icon-drawer2:before {
  content: "\e12d";
}
.icon-drawer3:before {
  content: "\e12e";
}
.icon-box:before {
  content: "\e12f";
}
.icon-box-add:before {
  content: "\e130";
}
.icon-box-remove:before {
  content: "\e131";
}
.icon-download:before {
  content: "\e132";
}
.icon-upload:before {
  content: "\e133";
}
.icon-disk:before {
  content: "\e134";
}
.icon-cd:before {
  content: "\e135";
}
.icon-storage:before {
  content: "\e136";
}
.icon-storage2:before {
  content: "\e137";
}
.icon-database:before {
  content: "\e138";
}
.icon-database2:before {
  content: "\e139";
}
.icon-database3:before {
  content: "\e13a";
}
.icon-undo:before {
  content: "\e13b";
}
.icon-redo:before {
  content: "\e13c";
}
.icon-rotate:before {
  content: "\e13d";
}
.icon-rotate2:before {
  content: "\e13e";
}
.icon-flip:before {
  content: "\e13f";
}
.icon-flip2:before {
  content: "\e140";
}
.icon-unite:before {
  content: "\e141";
}
.icon-subtract:before {
  content: "\e142";
}
.icon-interset:before {
  content: "\e143";
}
.icon-exclude:before {
  content: "\e144";
}
.icon-align-left:before {
  content: "\e145";
}
.icon-align-center-horizontal:before {
  content: "\e146";
}
.icon-align-right:before {
  content: "\e147";
}
.icon-align-top:before {
  content: "\e148";
}
.icon-align-center-vertical:before {
  content: "\e149";
}
.icon-align-bottom:before {
  content: "\e14a";
}
.icon-undo2:before {
  content: "\e14b";
}
.icon-redo2:before {
  content: "\e14c";
}
.icon-forward:before {
  content: "\e14d";
}
.icon-reply:before {
  content: "\e14e";
}
.icon-reply2:before {
  content: "\e14f";
}
.icon-bubble:before {
  content: "\e150";
}
.icon-bubbles:before {
  content: "\e151";
}
.icon-bubbles2:before {
  content: "\e152";
}
.icon-bubble2:before {
  content: "\e153";
}
.icon-bubbles3:before {
  content: "\e154";
}
.icon-bubbles4:before {
  content: "\e155";
}
.icon-bubble-notification:before {
  content: "\e156";
}
.icon-bubbles5:before {
  content: "\e157";
}
.icon-bubbles6:before {
  content: "\e158";
}
.icon-bubble3:before {
  content: "\e159";
}
.icon-bubble-dots:before {
  content: "\e15a";
}
.icon-bubble4:before {
  content: "\e15b";
}
.icon-bubble5:before {
  content: "\e15c";
}
.icon-bubble-dots2:before {
  content: "\e15d";
}
.icon-bubble6:before {
  content: "\e15e";
}
.icon-bubble7:before {
  content: "\e15f";
}
.icon-bubble8:before {
  content: "\e160";
}
.icon-bubbles7:before {
  content: "\e161";
}
.icon-bubble9:before {
  content: "\e162";
}
.icon-bubbles8:before {
  content: "\e163";
}
.icon-bubble10:before {
  content: "\e164";
}
.icon-bubble-dots3:before {
  content: "\e165";
}
.icon-bubble11:before {
  content: "\e166";
}
.icon-bubble12:before {
  content: "\e167";
}
.icon-bubble-dots4:before {
  content: "\e168";
}
.icon-bubble13:before {
  content: "\e169";
}
.icon-bubbles9:before {
  content: "\e16a";
}
.icon-bubbles10:before {
  content: "\e16b";
}
.icon-bubble-blocked:before {
  content: "\e16c";
}
.icon-bubble-quote:before {
  content: "\e16d";
}
.icon-bubble-user:before {
  content: "\e16e";
}
.icon-bubble-check:before {
  content: "\e16f";
}
.icon-bubble-video-chat:before {
  content: "\e170";
}
.icon-bubble-link:before {
  content: "\e171";
}
.icon-bubble-locked:before {
  content: "\e172";
}
.icon-bubble-star:before {
  content: "\e173";
}
.icon-bubble-heart:before {
  content: "\e174";
}
.icon-bubble-paperclip:before {
  content: "\e175";
}
.icon-bubble-cancel:before {
  content: "\e176";
}
.icon-bubble-plus:before {
  content: "\e177";
}
.icon-bubble-minus:before {
  content: "\e178";
}
.icon-bubble-notification2:before {
  content: "\e179";
}
.icon-bubble-trash:before {
  content: "\e17a";
}
.icon-bubble-left:before {
  content: "\e17b";
}
.icon-bubble-right:before {
  content: "\e17c";
}
.icon-bubble-up:before {
  content: "\e17d";
}
.icon-bubble-down:before {
  content: "\e17e";
}
.icon-bubble-first:before {
  content: "\e17f";
}
.icon-bubble-last:before {
  content: "\e180";
}
.icon-bubble-replu:before {
  content: "\e181";
}
.icon-bubble-forward:before {
  content: "\e182";
}
.icon-bubble-reply:before {
  content: "\e183";
}
.icon-bubble-forward2:before {
  content: "\e184";
}
.icon-user:before {
  content: "\e185";
}
.icon-users:before {
  content: "\e186";
}
.icon-user-plus:before {
  content: "\e187";
}
.icon-user-plus2:before {
  content: "\e188";
}
.icon-user-minus:before {
  content: "\e189";
}
.icon-user-minus2:before {
  content: "\e18a";
}
.icon-user-cancel:before {
  content: "\e18b";
}
.icon-user-block:before {
  content: "\e18c";
}
.icon-users2:before {
  content: "\e18d";
}
.icon-user2:before {
  content: "\e18e";
}
.icon-users3:before {
  content: "\e18f";
}
.icon-user-plus3:before {
  content: "\e190";
}
.icon-user-minus3:before {
  content: "\e191";
}
.icon-user-cancel2:before {
  content: "\e192";
}
.icon-user-block2:before {
  content: "\e193";
}
.icon-user3:before {
  content: "\e194";
}
.icon-user4:before {
  content: "\e195";
}
.icon-user5:before {
  content: "\e196";
}
.icon-user6:before {
  content: "\e197";
}
.icon-users4:before {
  content: "\e198";
}
.icon-user7:before {
  content: "\e199";
}
.icon-user8:before {
  content: "\e19a";
}
.icon-users5:before {
  content: "\e19b";
}
.icon-vcard:before {
  content: "\e19c";
}
.icon-tshirt:before {
  content: "\e19d";
}
.icon-hanger:before {
  content: "\e19e";
}
.icon-quotes-left:before {
  content: "\e19f";
}
.icon-quotes-right:before {
  content: "\e1a0";
}
.icon-quotes-right2:before {
  content: "\e1a1";
}
.icon-quotes-right3:before {
  content: "\e1a2";
}
.icon-busy:before {
  content: "\e1a3";
}
.icon-busy2:before {
  content: "\e1a4";
}
.icon-busy3:before {
  content: "\e1a5";
}
.icon-busy4:before {
  content: "\e1a6";
}
.icon-spinner:before {
  content: "\e1a7";
}
.icon-spinner2:before {
  content: "\e1a8";
}
.icon-spinner3:before {
  content: "\e1a9";
}
.icon-spinner4:before {
  content: "\e1aa";
}
.icon-spinner5:before {
  content: "\e1ab";
}
.icon-spinner6:before {
  content: "\e1ac";
}
.icon-spinner7:before {
  content: "\e1ad";
}
.icon-spinner8:before {
  content: "\e1ae";
}
.icon-spinner9:before {
  content: "\e1af";
}
.icon-spinner10:before {
  content: "\e1b0";
}
.icon-spinner11:before {
  content: "\e1b1";
}
.icon-spinner12:before {
  content: "\e1b2";
}
.icon-microscope:before {
  content: "\e1b3";
}
.icon-binoculars:before {
  content: "\e1b4";
}
.icon-binoculars2:before {
  content: "\e1b5";
}
.icon-search:before {
  content: "\e1b6";
}
.icon-search2:before {
  content: "\e1b7";
}
.icon-zoomin:before {
  content: "\e1b8";
}
.icon-zoomout:before {
  content: "\e1b9";
}
.icon-search3:before {
  content: "\e1ba";
}
.icon-search4:before {
  content: "\e1bb";
}
.icon-zoomin2:before {
  content: "\e1bc";
}
.icon-zoomout2:before {
  content: "\e1bd";
}
.icon-search5:before {
  content: "\e1be";
}
.icon-expand:before {
  content: "\e1bf";
}
.icon-contract:before {
  content: "\e1c0";
}
.icon-scale-up:before {
  content: "\e1c1";
}
.icon-scale-down:before {
  content: "\e1c2";
}
.icon-expand2:before {
  content: "\e1c3";
}
.icon-contract2:before {
  content: "\e1c4";
}
.icon-scale-up2:before {
  content: "\e1c5";
}
.icon-scale-down2:before {
  content: "\e1c6";
}
.icon-fullscreen:before {
  content: "\e1c7";
}
.icon-expand3:before {
  content: "\e1c8";
}
.icon-contract3:before {
  content: "\e1c9";
}
.icon-key:before {
  content: "\e1ca";
}
.icon-key2:before {
  content: "\e1cb";
}
.icon-key3:before {
  content: "\e1cc";
}
.icon-key4:before {
  content: "\e1cd";
}
.icon-key5:before {
  content: "\e1ce";
}
.icon-keyhole:before {
  content: "\e1cf";
}
.icon-lock:before {
  content: "\e1d0";
}
.icon-lock2:before {
  content: "\e1d1";
}
.icon-lock3:before {
  content: "\e1d2";
}
.icon-lock4:before {
  content: "\e1d3";
}
.icon-unlocked:before {
  content: "\e1d4";
}
.icon-lock5:before {
  content: "\e1d5";
}
.icon-unlocked2:before {
  content: "\e1d6";
}
.icon-wrench:before {
  content: "\e1d7";
}
.icon-wrench2:before {
  content: "\e1d8";
}
.icon-wrench3:before {
  content: "\e1d9";
}
.icon-wrench4:before {
  content: "\e1da";
}
.icon-settings:before {
  content: "\e1db";
}
.icon-equalizer:before {
  content: "\e1dc";
}
.icon-equalizer2:before {
  content: "\e1dd";
}
.icon-equalizer3:before {
  content: "\e1de";
}
.icon-cog:before {
  content: "\e1df";
}
.icon-cogs:before {
  content: "\e1e0";
}
.icon-cog2:before {
  content: "\e1e1";
}
.icon-cog3:before {
  content: "\e1e2";
}
.icon-cog4:before {
  content: "\e1e3";
}
.icon-cog5:before {
  content: "\e1e4";
}
.icon-cog6:before {
  content: "\e1e5";
}
.icon-cog7:before {
  content: "\e1e6";
}
.icon-factory:before {
  content: "\e1e7";
}
.icon-hammer:before {
  content: "\e1e8";
}
.icon-tools:before {
  content: "\e1e9";
}
.icon-screwdriver:before {
  content: "\e1ea";
}
.icon-screwdriver2:before {
  content: "\e1eb";
}
.icon-wand:before {
  content: "\e1ec";
}
.icon-wand2:before {
  content: "\e1ed";
}
.icon-health:before {
  content: "\e1ee";
}
.icon-aid:before {
  content: "\e1ef";
}
.icon-patch:before {
  content: "\e1f0";
}
.icon-bug:before {
  content: "\e1f1";
}
.icon-bug2:before {
  content: "\e1f2";
}
.icon-inject:before {
  content: "\e1f3";
}
.icon-inject2:before {
  content: "\e1f4";
}
.icon-construction:before {
  content: "\e1f5";
}
.icon-cone:before {
  content: "\e1f6";
}
.icon-pie:before {
  content: "\e1f7";
}
.icon-pie2:before {
  content: "\e1f8";
}
.icon-pie3:before {
  content: "\e1f9";
}
.icon-pie4:before {
  content: "\e1fa";
}
.icon-pie5:before {
  content: "\e1fb";
}
.icon-pie6:before {
  content: "\e1fc";
}
.icon-pie7:before {
  content: "\e1fd";
}
.icon-stats:before {
  content: "\e1fe";
}
.icon-stats2:before {
  content: "\e1ff";
}
.icon-stats3:before {
  content: "\e200";
}
.icon-bars:before {
  content: "\e201";
}
.icon-bars2:before {
  content: "\e202";
}
.icon-bars3:before {
  content: "\e203";
}
.icon-bars4:before {
  content: "\e204";
}
.icon-bars5:before {
  content: "\e205";
}
.icon-bars6:before {
  content: "\e206";
}
.icon-stats-up:before {
  content: "\e207";
}
.icon-stats-down:before {
  content: "\e208";
}
.icon-stairs-down:before {
  content: "\e209";
}
.icon-stairs-down2:before {
  content: "\e20a";
}
.icon-chart:before {
  content: "\e20b";
}
.icon-stairs:before {
  content: "\e20c";
}
.icon-stairs2:before {
  content: "\e20d";
}
.icon-ladder:before {
  content: "\e20e";
}
.icon-cake:before {
  content: "\e20f";
}
.icon-gift:before {
  content: "\e210";
}
.icon-gift2:before {
  content: "\e211";
}
.icon-balloon:before {
  content: "\e212";
}
.icon-rating:before {
  content: "\e213";
}
.icon-rating2:before {
  content: "\e214";
}
.icon-rating3:before {
  content: "\e215";
}
.icon-podium:before {
  content: "\e216";
}
.icon-medal:before {
  content: "\e217";
}
.icon-medal2:before {
  content: "\e218";
}
.icon-medal3:before {
  content: "\e219";
}
.icon-medal4:before {
  content: "\e21a";
}
.icon-medal5:before {
  content: "\e21b";
}
.icon-crown:before {
  content: "\e21c";
}
.icon-trophy:before {
  content: "\e21d";
}
.icon-trophy2:before {
  content: "\e21e";
}
.icon-trophy-star:before {
  content: "\e21f";
}
.icon-diamond:before {
  content: "\e220";
}
.icon-diamond2:before {
  content: "\e221";
}
.icon-glass:before {
  content: "\e222";
}
.icon-glass2:before {
  content: "\e223";
}
.icon-bottle:before {
  content: "\e224";
}
.icon-bottle2:before {
  content: "\e225";
}
.icon-mug:before {
  content: "\e226";
}
.icon-food:before {
  content: "\e227";
}
.icon-food2:before {
  content: "\e228";
}
.icon-hamburger:before {
  content: "\e229";
}
.icon-cup:before {
  content: "\e22a";
}
.icon-cup2:before {
  content: "\e22b";
}
.icon-leaf:before {
  content: "\e22c";
}
.icon-leaf2:before {
  content: "\e22d";
}
.icon-apple-fruit:before {
  content: "\e22e";
}
.icon-tree:before {
  content: "\e22f";
}
.icon-tree2:before {
  content: "\e230";
}
.icon-paw:before {
  content: "\e231";
}
.icon-steps:before {
  content: "\e232";
}
.icon-flower:before {
  content: "\e233";
}
.icon-rocket:before {
  content: "\e234";
}
.icon-meter:before {
  content: "\e235";
}
.icon-meter2:before {
  content: "\e236";
}
.icon-meter-slow:before {
  content: "\e237";
}
.icon-meter-medium:before {
  content: "\e238";
}
.icon-meter-fast:before {
  content: "\e239";
}
.icon-dashboard:before {
  content: "\e23a";
}
.icon-hammer2:before {
  content: "\e23b";
}
.icon-balance:before {
  content: "\e23c";
}
.icon-bomb:before {
  content: "\e23d";
}
.icon-fire:before {
  content: "\e23e";
}
.icon-fire2:before {
  content: "\e23f";
}
.icon-lab:before {
  content: "\e240";
}
.icon-atom:before {
  content: "\e241";
}
.icon-atom2:before {
  content: "\e242";
}
.icon-magnet:before {
  content: "\e243";
}
.icon-magnet2:before {
  content: "\e244";
}
.icon-magnet3:before {
  content: "\e245";
}
.icon-magnet4:before {
  content: "\e246";
}
.icon-dumbbell:before {
  content: "\e247";
}
.icon-skull:before {
  content: "\e248";
}
.icon-skull2:before {
  content: "\e249";
}
.icon-skull3:before {
  content: "\e24a";
}
.icon-lamp:before {
  content: "\e24b";
}
.icon-lamp2:before {
  content: "\e24c";
}
.icon-lamp3:before {
  content: "\e24d";
}
.icon-lamp4:before {
  content: "\e24e";
}
.icon-remove:before {
  content: "\e24f";
}
.icon-remove2:before {
  content: "\e250";
}
.icon-remove3:before {
  content: "\e251";
}
.icon-remove4:before {
  content: "\e252";
}
.icon-remove5:before {
  content: "\e253";
}
.icon-remove6:before {
  content: "\e254";
}
.icon-remove7:before {
  content: "\e255";
}
.icon-remove8:before {
  content: "\e256";
}
.icon-briefcase:before {
  content: "\e257";
}
.icon-briefcase2:before {
  content: "\e258";
}
.icon-briefcase3:before {
  content: "\e259";
}
.icon-airplane:before {
  content: "\e25a";
}
.icon-airplane2:before {
  content: "\e25b";
}
.icon-paperplane:before {
  content: "\e25c";
}
.icon-car:before {
  content: "\e25d";
}
.icon-gas-pump:before {
  content: "\e25e";
}
.icon-bus:before {
  content: "\e25f";
}
.icon-truck:before {
  content: "\e260";
}
.icon-bike:before {
  content: "\e261";
}
.icon-road:before {
  content: "\e262";
}
.icon-train:before {
  content: "\e263";
}
.icon-ship:before {
  content: "\e264";
}
.icon-boat:before {
  content: "\e265";
}
.icon-cube:before {
  content: "\e266";
}
.icon-cube2:before {
  content: "\e267";
}
.icon-cube3:before {
  content: "\e268";
}
.icon-cube4:before {
  content: "\e269";
}
.icon-pyramid:before {
  content: "\e26a";
}
.icon-pyramid2:before {
  content: "\e26b";
}
.icon-cylinder:before {
  content: "\e26c";
}
.icon-package:before {
  content: "\e26d";
}
.icon-puzzle:before {
  content: "\e26e";
}
.icon-puzzle2:before {
  content: "\e26f";
}
.icon-puzzle3:before {
  content: "\e270";
}
.icon-puzzle4:before {
  content: "\e271";
}
.icon-glasses:before {
  content: "\e272";
}
.icon-glasses2:before {
  content: "\e273";
}
.icon-glasses3:before {
  content: "\e274";
}
.icon-sunglasses:before {
  content: "\e275";
}
.icon-accessibility:before {
  content: "\e276";
}
.icon-accessibility2:before {
  content: "\e277";
}
.icon-brain:before {
  content: "\e278";
}
.icon-target:before {
  content: "\e279";
}
.icon-target2:before {
  content: "\e27a";
}
.icon-target3:before {
  content: "\e27b";
}
.icon-gun:before {
  content: "\e27c";
}
.icon-gun-ban:before {
  content: "\e27d";
}
.icon-shield:before {
  content: "\e27e";
}
.icon-shield2:before {
  content: "\e27f";
}
.icon-shield3:before {
  content: "\e280";
}
.icon-shield4:before {
  content: "\e281";
}
.icon-soccer:before {
  content: "\e282";
}
.icon-football:before {
  content: "\e283";
}
.icon-baseball:before {
  content: "\e284";
}
.icon-basketball:before {
  content: "\e285";
}
.icon-golf:before {
  content: "\e286";
}
.icon-hockey:before {
  content: "\e287";
}
.icon-racing:before {
  content: "\e288";
}
.icon-eightball:before {
  content: "\e289";
}
.icon-bowlingball:before {
  content: "\e28a";
}
.icon-bowling:before {
  content: "\e28b";
}
.icon-bowling2:before {
  content: "\e28c";
}
.icon-lightning:before {
  content: "\e28d";
}
.icon-power:before {
  content: "\e28e";
}
.icon-power2:before {
  content: "\e28f";
}
.icon-switch:before {
  content: "\e290";
}
.icon-powercord:before {
  content: "\e291";
}
.icon-cord:before {
  content: "\e292";
}
.icon-socket:before {
  content: "\e293";
}
.icon-clipboard:before {
  content: "\e294";
}
.icon-clipboard2:before {
  content: "\e295";
}
.icon-signup:before {
  content: "\e296";
}
.icon-clipboard3:before {
  content: "\e297";
}
.icon-clipboard4:before {
  content: "\e298";
}
.icon-list:before {
  content: "\e299";
}
.icon-list2:before {
  content: "\e29a";
}
.icon-list3:before {
  content: "\e29b";
}
.icon-numbered-list:before {
  content: "\e29c";
}
.icon-list4:before {
  content: "\e29d";
}
.icon-list5:before {
  content: "\e29e";
}
.icon-playlist:before {
  content: "\e29f";
}
.icon-grid:before {
  content: "\e2a0";
}
.icon-grid2:before {
  content: "\e2a1";
}
.icon-grid3:before {
  content: "\e2a2";
}
.icon-grid4:before {
  content: "\e2a3";
}
.icon-grid5:before {
  content: "\e2a4";
}
.icon-grid6:before {
  content: "\e2a5";
}
.icon-tree3:before {
  content: "\e2a6";
}
.icon-tree4:before {
  content: "\e2a7";
}
.icon-tree5:before {
  content: "\e2a8";
}
.icon-menu:before {
  content: "\e2a9";
}
.icon-menu2:before {
  content: "\e2aa";
}
.icon-circle-small:before {
  content: "\e2ab";
}
.icon-menu3:before {
  content: "\e2ac";
}
.icon-menu4:before {
  content: "\e2ad";
}
.icon-menu5:before {
  content: "\e2ae";
}
.icon-menu6:before {
  content: "\e2af";
}
.icon-menu7:before {
  content: "\e2b0";
}
.icon-menu8:before {
  content: "\e2b1";
}
.icon-menu9:before {
  content: "\e2b2";
}
.icon-cloud:before {
  content: "\e2b3";
}
.icon-cloud2:before {
  content: "\e2b4";
}
.icon-cloud3:before {
  content: "\e2b5";
}
.icon-cloud-download:before {
  content: "\e2b6";
}
.icon-cloud-upload:before {
  content: "\e2b7";
}
.icon-download2:before {
  content: "\e2b8";
}
.icon-upload2:before {
  content: "\e2b9";
}
.icon-download3:before {
  content: "\e2ba";
}
.icon-upload3:before {
  content: "\e2bb";
}
.icon-download4:before {
  content: "\e2bc";
}
.icon-upload4:before {
  content: "\e2bd";
}
.icon-download5:before {
  content: "\e2be";
}
.icon-upload5:before {
  content: "\e2bf";
}
.icon-download6:before {
  content: "\e2c0";
}
.icon-upload6:before {
  content: "\e2c1";
}
.icon-download7:before {
  content: "\e2c2";
}
.icon-upload7:before {
  content: "\e2c3";
}
.icon-globe:before {
  content: "\e2c4";
}
.icon-globe2:before {
  content: "\e2c5";
}
.icon-globe3:before {
  content: "\e2c6";
}
.icon-earth:before {
  content: "\e2c7";
}
.icon-network:before {
  content: "\e2c8";
}
.icon-link:before {
  content: "\e2c9";
}
.icon-link2:before {
  content: "\e2ca";
}
.icon-link3:before {
  content: "\e2cb";
}
.icon-link22:before {
  content: "\e2cc";
}
.icon-link4:before {
  content: "\e2cd";
}
.icon-link5:before {
  content: "\e2ce";
}
.icon-link6:before {
  content: "\e2cf";
}
.icon-anchor:before {
  content: "\e2d0";
}
.icon-flag:before {
  content: "\e2d1";
}
.icon-flag2:before {
  content: "\e2d2";
}
.icon-flag3:before {
  content: "\e2d3";
}
.icon-flag4:before {
  content: "\e2d4";
}
.icon-flag5:before {
  content: "\e2d5";
}
.icon-flag6:before {
  content: "\e2d6";
}
.icon-attachment:before {
  content: "\e2d7";
}
.icon-attachment2:before {
  content: "\e2d8";
}
.icon-eye:before {
  content: "\e2d9";
}
.icon-eye-blocked:before {
  content: "\e2da";
}
.icon-eye2:before {
  content: "\e2db";
}
.icon-eye3:before {
  content: "\e2dc";
}
.icon-eye-blocked2:before {
  content: "\e2dd";
}
.icon-eye4:before {
  content: "\e2de";
}
.icon-eye5:before {
  content: "\e2df";
}
.icon-eye6:before {
  content: "\e2e0";
}
.icon-eye7:before {
  content: "\e2e1";
}
.icon-eye8:before {
  content: "\e2e2";
}
.icon-bookmark:before {
  content: "\e2e3";
}
.icon-bookmark2:before {
  content: "\e2e4";
}
.icon-bookmarks:before {
  content: "\e2e5";
}
.icon-bookmark3:before {
  content: "\e2e6";
}
.icon-spotlight:before {
  content: "\e2e7";
}
.icon-starburst:before {
  content: "\e2e8";
}
.icon-snowflake:before {
  content: "\e2e9";
}
.icon-temperature:before {
  content: "\e2ea";
}
.icon-temperature2:before {
  content: "\e2eb";
}
.icon-weather-lightning:before {
  content: "\e2ec";
}
.icon-weather-rain:before {
  content: "\e2ed";
}
.icon-weather-snow:before {
  content: "\e2ee";
}
.icon-windy:before {
  content: "\e2ef";
}
.icon-fan:before {
  content: "\e2f0";
}
.icon-umbrella:before {
  content: "\e2f1";
}
.icon-sun:before {
  content: "\e2f2";
}
.icon-sun2:before {
  content: "\e2f3";
}
.icon-brightness-high:before {
  content: "\e2f4";
}
.icon-brightness-medium:before {
  content: "\e2f5";
}
.icon-brightness-low:before {
  content: "\e2f6";
}
.icon-brightness-contrast:before {
  content: "\e2f7";
}
.icon-contrast:before {
  content: "\e2f8";
}
.icon-moon:before {
  content: "\e2f9";
}
.icon-bed:before {
  content: "\e2fa";
}
.icon-bed2:before {
  content: "\e2fb";
}
.icon-star:before {
  content: "\e2fc";
}
.icon-star2:before {
  content: "\e2fd";
}
.icon-star3:before {
  content: "\e2fe";
}
.icon-star4:before {
  content: "\e2ff";
}
.icon-star5:before {
  content: "\e300";
}
.icon-star6:before {
  content: "\e301";
}
.icon-heart:before {
  content: "\e302";
}
.icon-heart2:before {
  content: "\e303";
}
.icon-heart3:before {
  content: "\e304";
}
.icon-heart4:before {
  content: "\e305";
}
.icon-heart-broken:before {
  content: "\e306";
}
.icon-heart5:before {
  content: "\e307";
}
.icon-heart6:before {
  content: "\e308";
}
.icon-heart-broken2:before {
  content: "\e309";
}
.icon-heart7:before {
  content: "\e30a";
}
.icon-heart8:before {
  content: "\e30b";
}
.icon-heart-broken3:before {
  content: "\e30c";
}
.icon-lips:before {
  content: "\e30d";
}
.icon-lips2:before {
  content: "\e30e";
}
.icon-thumbs-up:before {
  content: "\e30f";
}
.icon-thumbs-up2:before {
  content: "\e310";
}
.icon-thumbs-down:before {
  content: "\e311";
}
.icon-thumbs-down2:before {
  content: "\e312";
}
.icon-thumbs-up3:before {
  content: "\e313";
}
.icon-thumbs-up4:before {
  content: "\e314";
}
.icon-thumbs-up5:before {
  content: "\e315";
}
.icon-thumbs-up6:before {
  content: "\e316";
}
.icon-people:before {
  content: "\e317";
}
.icon-man:before {
  content: "\e318";
}
.icon-male:before {
  content: "\e319";
}
.icon-woman:before {
  content: "\e31a";
}
.icon-female:before {
  content: "\e31b";
}
.icon-peace:before {
  content: "\e31c";
}
.icon-yin-yang:before {
  content: "\e31d";
}
.icon-happy:before {
  content: "\e31e";
}
.icon-happy2:before {
  content: "\e31f";
}
.icon-smiley:before {
  content: "\e320";
}
.icon-smiley2:before {
  content: "\e321";
}
.icon-tongue:before {
  content: "\e322";
}
.icon-tongue2:before {
  content: "\e323";
}
.icon-sad:before {
  content: "\e324";
}
.icon-sad2:before {
  content: "\e325";
}
.icon-wink:before {
  content: "\e326";
}
.icon-wink2:before {
  content: "\e327";
}
.icon-grin:before {
  content: "\e328";
}
.icon-grin2:before {
  content: "\e329";
}
.icon-cool:before {
  content: "\e32a";
}
.icon-cool2:before {
  content: "\e32b";
}
.icon-angry:before {
  content: "\e32c";
}
.icon-angry2:before {
  content: "\e32d";
}
.icon-evil:before {
  content: "\e32e";
}
.icon-evil2:before {
  content: "\e32f";
}
.icon-shocked:before {
  content: "\e330";
}
.icon-shocked2:before {
  content: "\e331";
}
.icon-confused:before {
  content: "\e332";
}
.icon-confused2:before {
  content: "\e333";
}
.icon-neutral:before {
  content: "\e334";
}
.icon-neutral2:before {
  content: "\e335";
}
.icon-wondering:before {
  content: "\e336";
}
.icon-wondering2:before {
  content: "\e337";
}
.icon-cursor:before {
  content: "\e338";
}
.icon-cursor2:before {
  content: "\e339";
}
.icon-point-up:before {
  content: "\e33a";
}
.icon-point-right:before {
  content: "\e33b";
}
.icon-point-down:before {
  content: "\e33c";
}
.icon-point-left:before {
  content: "\e33d";
}
.icon-pointer:before {
  content: "\e33e";
}
.icon-hand:before {
  content: "\e33f";
}
.icon-stack-empty:before {
  content: "\e340";
}
.icon-stack-plus:before {
  content: "\e341";
}
.icon-stack-minus:before {
  content: "\e342";
}
.icon-stack-star:before {
  content: "\e343";
}
.icon-stack-picture:before {
  content: "\e344";
}
.icon-stack-down:before {
  content: "\e345";
}
.icon-stack-up:before {
  content: "\e346";
}
.icon-stack-cancel:before {
  content: "\e347";
}
.icon-stack-checkmark:before {
  content: "\e348";
}
.icon-stack-list:before {
  content: "\e349";
}
.icon-stack-clubs:before {
  content: "\e34a";
}
.icon-stack-spades:before {
  content: "\e34b";
}
.icon-stack-hearts:before {
  content: "\e34c";
}
.icon-stack-diamonds:before {
  content: "\e34d";
}
.icon-stack-user:before {
  content: "\e34e";
}
.icon-stack4:before {
  content: "\e34f";
}
.icon-stack-music:before {
  content: "\e350";
}
.icon-stack-play:before {
  content: "\e351";
}
.icon-move:before {
  content: "\e352";
}
.icon-resize:before {
  content: "\e353";
}
.icon-resize2:before {
  content: "\e354";
}
.icon-warning:before {
  content: "\e355";
}
.icon-warning2:before {
  content: "\e356";
}
.icon-notification:before {
  content: "\e357";
}
.icon-notification2:before {
  content: "\e358";
}
.icon-question:before {
  content: "\e359";
}
.icon-question2:before {
  content: "\e35a";
}
.icon-question3:before {
  content: "\e35b";
}
.icon-question4:before {
  content: "\e35c";
}
.icon-question5:before {
  content: "\e35d";
}
.icon-plus-circle:before {
  content: "\e35e";
}
.icon-plus-circle2:before {
  content: "\e35f";
}
.icon-minus-circle:before {
  content: "\e360";
}
.icon-minus-circle2:before {
  content: "\e361";
}
.icon-info:before {
  content: "\e362";
}
.icon-info2:before {
  content: "\e363";
}
.icon-blocked:before {
  content: "\e364";
}
.icon-cancel-circle:before {
  content: "\e365";
}
.icon-cancel-circle2:before {
  content: "\e366";
}
.icon-checkmark-circle:before {
  content: "\e367";
}
.icon-checkmark-circle2:before {
  content: "\e368";
}
.icon-cancel:before {
  content: "\e369";
}
.icon-spam:before {
  content: "\e36a";
}
.icon-close:before {
  content: "\e36b";
}
.icon-close2:before {
  content: "\e36c";
}
.icon-close3:before {
  content: "\e36d";
}
.icon-close4:before {
  content: "\e36e";
}
.icon-close5:before {
  content: "\e36f";
}
.icon-checkmark:before {
  content: "\e370";
}
.icon-checkmark2:before {
  content: "\e371";
}
.icon-checkmark3:before {
  content: "\e372";
}
.icon-checkmark4:before {
  content: "\e373";
}
.icon-spell-check:before {
  content: "\e374";
}
.icon-minus:before {
  content: "\e375";
}
.icon-plus:before {
  content: "\e376";
}
.icon-minus2:before {
  content: "\e377";
}
.icon-plus2:before {
  content: "\e378";
}
.icon-enter:before {
  content: "\e379";
}
.icon-exit:before {
  content: "\e37a";
}
.icon-enter2:before {
  content: "\e37b";
}
.icon-exit2:before {
  content: "\e37c";
}
.icon-enter3:before {
  content: "\e37d";
}
.icon-exit3:before {
  content: "\e37e";
}
.icon-exit4:before {
  content: "\e37f";
}
.icon-play3:before {
  content: "\e380";
}
.icon-pause:before {
  content: "\e381";
}
.icon-stop:before {
  content: "\e382";
}
.icon-backward:before {
  content: "\e383";
}
.icon-forward2:before {
  content: "\e384";
}
.icon-play4:before {
  content: "\e385";
}
.icon-pause2:before {
  content: "\e386";
}
.icon-stop2:before {
  content: "\e387";
}
.icon-backward2:before {
  content: "\e388";
}
.icon-forward3:before {
  content: "\e389";
}
.icon-first:before {
  content: "\e38a";
}
.icon-last:before {
  content: "\e38b";
}
.icon-previous:before {
  content: "\e38c";
}
.icon-next:before {
  content: "\e38d";
}
.icon-eject:before {
  content: "\e38e";
}
.icon-volume-high:before {
  content: "\e38f";
}
.icon-volume-medium:before {
  content: "\e390";
}
.icon-volume-low:before {
  content: "\e391";
}
.icon-volume-mute:before {
  content: "\e392";
}
.icon-volume-mute2:before {
  content: "\e393";
}
.icon-volume-increase:before {
  content: "\e394";
}
.icon-volume-decrease:before {
  content: "\e395";
}
.icon-volume-high2:before {
  content: "\e396";
}
.icon-volume-medium2:before {
  content: "\e397";
}
.icon-volume-low2:before {
  content: "\e398";
}
.icon-volume-mute3:before {
  content: "\e399";
}
.icon-volume-mute4:before {
  content: "\e39a";
}
.icon-volume-increase2:before {
  content: "\e39b";
}
.icon-volume-decrease2:before {
  content: "\e39c";
}
.icon-volume5:before {
  content: "\e39d";
}
.icon-volume4:before {
  content: "\e39e";
}
.icon-volume3:before {
  content: "\e39f";
}
.icon-volume2:before {
  content: "\e3a0";
}
.icon-volume1:before {
  content: "\e3a1";
}
.icon-volume0:before {
  content: "\e3a2";
}
.icon-volume-mute5:before {
  content: "\e3a3";
}
.icon-volume-mute6:before {
  content: "\e3a4";
}
.icon-loop:before {
  content: "\e3a5";
}
.icon-loop2:before {
  content: "\e3a6";
}
.icon-loop3:before {
  content: "\e3a7";
}
.icon-loop4:before {
  content: "\e3a8";
}
.icon-loop5:before {
  content: "\e3a9";
}
.icon-shuffle:before {
  content: "\e3aa";
}
.icon-shuffle2:before {
  content: "\e3ab";
}
.icon-wave:before {
  content: "\e3ac";
}
.icon-wave2:before {
  content: "\e3ad";
}
.icon-arrow-first:before {
  content: "\e3ae";
}
.icon-arrow-right:before {
  content: "\e3af";
}
.icon-arrow-up:before {
  content: "\e3b0";
}
.icon-arrow-right2:before {
  content: "\e3b1";
}
.icon-arrow-down:before {
  content: "\e3b2";
}
.icon-arrow-left:before {
  content: "\e3b3";
}
.icon-arrow-up2:before {
  content: "\e3b4";
}
.icon-arrow-right3:before {
  content: "\e3b5";
}
.icon-arrow-down2:before {
  content: "\e3b6";
}
.icon-arrow-left2:before {
  content: "\e3b7";
}
.icon-arrow-up-left:before {
  content: "\e3b8";
}
.icon-arrow-up3:before {
  content: "\e3b9";
}
.icon-arrow-up-right:before {
  content: "\e3ba";
}
.icon-arrow-right4:before {
  content: "\e3bb";
}
.icon-arrow-down-right:before {
  content: "\e3bc";
}
.icon-arrow-down3:before {
  content: "\e3bd";
}
.icon-arrow-down-left:before {
  content: "\e3be";
}
.icon-arrow-left3:before {
  content: "\e3bf";
}
.icon-arrow-up-left2:before {
  content: "\e3c0";
}
.icon-arrow-up4:before {
  content: "\e3c1";
}
.icon-arrow-up-right2:before {
  content: "\e3c2";
}
.icon-arrow-right5:before {
  content: "\e3c3";
}
.icon-arrow-down-right2:before {
  content: "\e3c4";
}
.icon-arrow-down4:before {
  content: "\e3c5";
}
.icon-arrow-down-left2:before {
  content: "\e3c6";
}
.icon-arrow-left4:before {
  content: "\e3c7";
}
.icon-arrow-up-left3:before {
  content: "\e3c8";
}
.icon-arrow-up5:before {
  content: "\e3c9";
}
.icon-arrow-up-right3:before {
  content: "\e3ca";
}
.icon-arrow-right6:before {
  content: "\e3cb";
}
.icon-arrow-down-right3:before {
  content: "\e3cc";
}
.icon-arrow-down5:before {
  content: "\e3cd";
}
.icon-arrow-down-left3:before {
  content: "\e3ce";
}
.icon-arrow-left5:before {
  content: "\e3cf";
}
.icon-arrow-up-left4:before {
  content: "\e3d0";
}
.icon-arrow-up6:before {
  content: "\e3d1";
}
.icon-arrow-up-right4:before {
  content: "\e3d2";
}
.icon-arrow-right7:before {
  content: "\e3d3";
}
.icon-arrow-down-right4:before {
  content: "\e3d4";
}
.icon-arrow-down6:before {
  content: "\e3d5";
}
.icon-arrow-down-left4:before {
  content: "\e3d6";
}
.icon-arrow-left6:before {
  content: "\e3d7";
}
.icon-arrow:before {
  content: "\e3d8";
}
.icon-arrow2:before {
  content: "\e3d9";
}
.icon-arrow3:before {
  content: "\e3da";
}
.icon-arrow4:before {
  content: "\e3db";
}
.icon-arrow5:before {
  content: "\e3dc";
}
.icon-arrow6:before {
  content: "\e3dd";
}
.icon-arrow7:before {
  content: "\e3de";
}
.icon-arrow8:before {
  content: "\e3df";
}
.icon-arrow-up-left5:before {
  content: "\e3e0";
}
.icon-arrowsquare:before {
  content: "\e3e1";
}
.icon-arrow-up-right5:before {
  content: "\e3e2";
}
.icon-arrow-right8:before {
  content: "\e3e3";
}
.icon-arrow-down-right5:before {
  content: "\e3e4";
}
.icon-arrow-down7:before {
  content: "\e3e5";
}
.icon-arrow-down-left5:before {
  content: "\e3e6";
}
.icon-arrow-left7:before {
  content: "\e3e7";
}
.icon-arrow-up7:before {
  content: "\e3e8";
}
.icon-arrow-right9:before {
  content: "\e3e9";
}
.icon-arrow-down8:before {
  content: "\e3ea";
}
.icon-arrow-left8:before {
  content: "\e3eb";
}
.icon-arrow-up8:before {
  content: "\e3ec";
}
.icon-arrow-right10:before {
  content: "\e3ed";
}
.icon-arrow-bottom:before {
  content: "\e3ee";
}
.icon-arrow-left9:before {
  content: "\e3ef";
}
.icon-arrow-up-left6:before {
  content: "\e3f0";
}
.icon-arrow-up9:before {
  content: "\e3f1";
}
.icon-arrow-up-right6:before {
  content: "\e3f2";
}
.icon-arrow-right11:before {
  content: "\e3f3";
}
.icon-arrow-down-right6:before {
  content: "\e3f4";
}
.icon-arrow-down9:before {
  content: "\e3f5";
}
.icon-arrow-down-left6:before {
  content: "\e3f6";
}
.icon-arrow-left10:before {
  content: "\e3f7";
}
.icon-arrow-up-left7:before {
  content: "\e3f8";
}
.icon-arrow-up10:before {
  content: "\e3f9";
}
.icon-arrow-up-right7:before {
  content: "\e3fa";
}
.icon-arrow-right12:before {
  content: "\e3fb";
}
.icon-arrow-down-right7:before {
  content: "\e3fc";
}
.icon-arrow-down10:before {
  content: "\e3fd";
}
.icon-arrow-down-left7:before {
  content: "\e3fe";
}
.icon-arrow-left11:before {
  content: "\e3ff";
}
.icon-arrow-up11:before {
  content: "\e400";
}
.icon-arrow-right13:before {
  content: "\e401";
}
.icon-arrow-down11:before {
  content: "\e402";
}
.icon-arrow-left12:before {
  content: "\e403";
}
.icon-arrow-up12:before {
  content: "\e404";
}
.icon-arrow-right14:before {
  content: "\e405";
}
.icon-arrow-down12:before {
  content: "\e406";
}
.icon-arrow-left13:before {
  content: "\e407";
}
.icon-arrow-up13:before {
  content: "\e408";
}
.icon-arrow-right15:before {
  content: "\e409";
}
.icon-arrow-down13:before {
  content: "\e40a";
}
.icon-arrow-left14:before {
  content: "\e40b";
}
.icon-arrow-up14:before {
  content: "\e40c";
}
.icon-arrow-right16:before {
  content: "\e40d";
}
.icon-arrow-down14:before {
  content: "\e40e";
}
.icon-arrow-left15:before {
  content: "\e40f";
}
.icon-arrow-up15:before {
  content: "\e410";
}
.icon-arrow-right17:before {
  content: "\e411";
}
.icon-arrow-down15:before {
  content: "\e412";
}
.icon-arrow-left16:before {
  content: "\e413";
}
.icon-arrow-up16:before {
  content: "\e414";
}
.icon-arrow-right18:before {
  content: "\e415";
}
.icon-arrow-down16:before {
  content: "\e416";
}
.icon-arrow-left17:before {
  content: "\e417";
}
.icon-menu10:before {
  content: "\e418";
}
.icon-menu11:before {
  content: "\e419";
}
.icon-menu-close:before {
  content: "\e41a";
}
.icon-menu-close2:before {
  content: "\e41b";
}
.icon-enter4:before {
  content: "\e41c";
}
.icon-enter5:before {
  content: "\e41d";
}
.icon-esc:before {
  content: "\e41e";
}
.icon-backspace:before {
  content: "\e41f";
}
.icon-backspace2:before {
  content: "\e420";
}
.icon-backspace3:before {
  content: "\e421";
}
.icon-tab:before {
  content: "\e422";
}
.icon-transmission:before {
  content: "\e423";
}
.icon-transmission2:before {
  content: "\e424";
}
.icon-sort:before {
  content: "\e425";
}
.icon-sort2:before {
  content: "\e426";
}
.icon-key-keyboard:before {
  content: "\e427";
}
.icon-key-A:before {
  content: "\e428";
}
.icon-key-up:before {
  content: "\e429";
}
.icon-key-right:before {
  content: "\e42a";
}
.icon-key-down:before {
  content: "\e42b";
}
.icon-key-left:before {
  content: "\e42c";
}
.icon-command:before {
  content: "\e42d";
}
.icon-checkbox-checked:before {
  content: "\e42e";
}
.icon-checkbox-unchecked:before {
  content: "\e42f";
}
.icon-square:before {
  content: "\e430";
}
.icon-checkbox-partial:before {
  content: "\e431";
}
.icon-checkbox:before {
  content: "\e432";
}
.icon-checkbox-unchecked2:before {
  content: "\e433";
}
.icon-checkbox-partial2:before {
  content: "\e434";
}
.icon-checkbox-checked2:before {
  content: "\e435";
}
.icon-checkbox-unchecked3:before {
  content: "\e436";
}
.icon-checkbox-partial3:before {
  content: "\e437";
}
.icon-radio-checked:before {
  content: "\e438";
}
.icon-radio-unchecked:before {
  content: "\e439";
}
.icon-circle:before {
  content: "\e43a";
}
.icon-circle2:before {
  content: "\e43b";
}
.icon-crop:before {
  content: "\e43c";
}
.icon-crop2:before {
  content: "\e43d";
}
.icon-vector:before {
  content: "\e43e";
}
.icon-rulers:before {
  content: "\e43f";
}
.icon-scissors:before {
  content: "\e440";
}
.icon-scissors2:before {
  content: "\e441";
}
.icon-scissors3:before {
  content: "\e442";
}
.icon-filter:before {
  content: "\e443";
}
.icon-filter2:before {
  content: "\e444";
}
.icon-filter3:before {
  content: "\e445";
}
.icon-filter4:before {
  content: "\e446";
}
.icon-font:before {
  content: "\e447";
}
.icon-font-size:before {
  content: "\e448";
}
.icon-type:before {
  content: "\e449";
}
.icon-text-height:before {
  content: "\e44a";
}
.icon-text-width:before {
  content: "\e44b";
}
.icon-height:before {
  content: "\e44c";
}
.icon-width:before {
  content: "\e44d";
}
.icon-bold:before {
  content: "\e44e";
}
.icon-underline:before {
  content: "\e44f";
}
.icon-italic:before {
  content: "\e450";
}
.icon-strikethrough:before {
  content: "\e451";
}
.icon-strikethrough2:before {
  content: "\e452";
}
.icon-font-size2:before {
  content: "\e453";
}
.icon-bold2:before {
  content: "\e454";
}
.icon-underline2:before {
  content: "\e455";
}
.icon-italic2:before {
  content: "\e456";
}
.icon-strikethrough3:before {
  content: "\e457";
}
.icon-omega:before {
  content: "\e458";
}
.icon-sigma:before {
  content: "\e459";
}
.icon-nbsp:before {
  content: "\e45a";
}
.icon-page-break:before {
  content: "\e45b";
}
.icon-page-break2:before {
  content: "\e45c";
}
.icon-superscript:before {
  content: "\e45d";
}
.icon-subscript:before {
  content: "\e45e";
}
.icon-superscript2:before {
  content: "\e45f";
}
.icon-subscript2:before {
  content: "\e460";
}
.icon-text-color:before {
  content: "\e461";
}
.icon-highlight:before {
  content: "\e462";
}
.icon-pagebreak:before {
  content: "\e463";
}
.icon-clear-formatting:before {
  content: "\e464";
}
.icon-table:before {
  content: "\e465";
}
.icon-table2:before {
  content: "\e466";
}
.icon-insert-template:before {
  content: "\e467";
}
.icon-pilcrow:before {
  content: "\e468";
}
.icon-lefttoright:before {
  content: "\e469";
}
.icon-righttoleft:before {
  content: "\e46a";
}
.icon-paragraph-left:before {
  content: "\e46b";
}
.icon-paragraph-center:before {
  content: "\e46c";
}
.icon-paragraph-right:before {
  content: "\e46d";
}
.icon-paragraph-justify:before {
  content: "\e46e";
}
.icon-paragraph-left2:before {
  content: "\e46f";
}
.icon-paragraph-center2:before {
  content: "\e470";
}
.icon-paragraph-right2:before {
  content: "\e471";
}
.icon-paragraph-justify2:before {
  content: "\e472";
}
.icon-indent-increase:before {
  content: "\e473";
}
.icon-indent-decrease:before {
  content: "\e474";
}
.icon-paragraph-left3:before {
  content: "\e475";
}
.icon-paragraph-center3:before {
  content: "\e476";
}
.icon-paragraph-right3:before {
  content: "\e477";
}
.icon-paragraph-justify3:before {
  content: "\e478";
}
.icon-indent-increase2:before {
  content: "\e479";
}
.icon-indent-decrease2:before {
  content: "\e47a";
}
.icon-share:before {
  content: "\e47b";
}
.icon-newtab:before {
  content: "\e47c";
}
.icon-newtab2:before {
  content: "\e47d";
}
.icon-popout:before {
  content: "\e47e";
}
.icon-embed:before {
  content: "\e47f";
}
.icon-code:before {
  content: "\e480";
}
.icon-console:before {
  content: "\e481";
}
.icon-sevensegment0:before {
  content: "\e482";
}
.icon-sevensegment1:before {
  content: "\e483";
}
.icon-sevensegment2:before {
  content: "\e484";
}
.icon-sevensegment3:before {
  content: "\e485";
}
.icon-sevensegment4:before {
  content: "\e486";
}
.icon-sevensegment5:before {
  content: "\e487";
}
.icon-sevensegment6:before {
  content: "\e488";
}
.icon-sevensegment7:before {
  content: "\e489";
}
.icon-sevensegment8:before {
  content: "\e48a";
}
.icon-sevensegment9:before {
  content: "\e48b";
}
.icon-share2:before {
  content: "\e48c";
}
.icon-share3:before {
  content: "\e48d";
}
.icon-mail:before {
  content: "\e48e";
}
.icon-mail2:before {
  content: "\e48f";
}
.icon-mail3:before {
  content: "\e490";
}
.icon-mail4:before {
  content: "\e491";
}
.icon-google:before {
  content: "\e492";
}
.icon-googleplus:before {
  content: "\e493";
}
.icon-googleplus2:before {
  content: "\e494";
}
.icon-googleplus3:before {
  content: "\e495";
}
.icon-googleplus4:before {
  content: "\e496";
}
.icon-google-drive:before {
  content: "\e497";
}
.icon-facebook:before {
  content: "\e498";
}
.icon-facebook2:before {
  content: "\e499";
}
.icon-facebook3:before {
  content: "\e49a";
}
.icon-facebook4:before {
  content: "\e49b";
}
.icon-instagram:before {
  content: "\e49c";
}
.icon-twitter:before {
  content: "\e49d";
}
.icon-twitter2:before {
  content: "\e49e";
}
.icon-twitter3:before {
  content: "\e49f";
}
.icon-feed2:before {
  content: "\e4a0";
}
.icon-feed3:before {
  content: "\e4a1";
}
.icon-feed4:before {
  content: "\e4a2";
}
.icon-youtube:before {
  content: "\e4a3";
}
.icon-youtube2:before {
  content: "\e4a4";
}
.icon-vimeo:before {
  content: "\e4a5";
}
.icon-vimeo2:before {
  content: "\e4a6";
}
.icon-vimeo3:before {
  content: "\e4a7";
}
.icon-lanyrd:before {
  content: "\e4a8";
}
.icon-flickr:before {
  content: "\e4a9";
}
.icon-flickr2:before {
  content: "\e4aa";
}
.icon-flickr3:before {
  content: "\e4ab";
}
.icon-flickr4:before {
  content: "\e4ac";
}
.icon-picassa:before {
  content: "\e4ad";
}
.icon-picassa2:before {
  content: "\e4ae";
}
.icon-dribbble:before {
  content: "\e4af";
}
.icon-dribbble2:before {
  content: "\e4b0";
}
.icon-dribbble3:before {
  content: "\e4b1";
}
.icon-forrst:before {
  content: "\e4b2";
}
.icon-forrst2:before {
  content: "\e4b3";
}
.icon-deviantart:before {
  content: "\e4b4";
}
.icon-deviantart2:before {
  content: "\e4b5";
}
.icon-steam:before {
  content: "\e4b6";
}
.icon-steam2:before {
  content: "\e4b7";
}
.icon-github:before {
  content: "\e4b8";
}
.icon-github2:before {
  content: "\e4b9";
}
.icon-github3:before {
  content: "\e4ba";
}
.icon-github4:before {
  content: "\e4bb";
}
.icon-github5:before {
  content: "\e4bc";
}
.icon-wordpress:before {
  content: "\e4bd";
}
.icon-wordpress2:before {
  content: "\e4be";
}
.icon-joomla:before {
  content: "\e4bf";
}
.icon-blogger:before {
  content: "\e4c0";
}
.icon-blogger2:before {
  content: "\e4c1";
}
.icon-tumblr:before {
  content: "\e4c2";
}
.icon-tumblr2:before {
  content: "\e4c3";
}
.icon-yahoo:before {
  content: "\e4c4";
}
.icon-tux:before {
  content: "\e4c5";
}
.icon-apple:before {
  content: "\e4c6";
}
.icon-finder:before {
  content: "\e4c7";
}
.icon-android:before {
  content: "\e4c8";
}
.icon-windows:before {
  content: "\e4c9";
}
.icon-windows8:before {
  content: "\e4ca";
}
.icon-soundcloud:before {
  content: "\e4cb";
}
.icon-soundcloud2:before {
  content: "\e4cc";
}
.icon-skype:before {
  content: "\e4cd";
}
.icon-reddit:before {
  content: "\e4ce";
}
.icon-linkedin:before {
  content: "\e4cf";
}
.icon-lastfm:before {
  content: "\e4d0";
}
.icon-lastfm2:before {
  content: "\e4d1";
}
.icon-delicious:before {
  content: "\e4d2";
}
.icon-stumbleupon:before {
  content: "\e4d3";
}
.icon-stumbleupon2:before {
  content: "\e4d4";
}
.icon-stackoverflow:before {
  content: "\e4d5";
}
.icon-pinterest:before {
  content: "\e4d6";
}
.icon-pinterest2:before {
  content: "\e4d7";
}
.icon-xing:before {
  content: "\e4d8";
}
.icon-xing2:before {
  content: "\e4d9";
}
.icon-flattr:before {
  content: "\e4da";
}
.icon-foursquare:before {
  content: "\e4db";
}
.icon-foursquare2:before {
  content: "\e4dc";
}
.icon-paypal:before {
  content: "\e4dd";
}
.icon-paypal2:before {
  content: "\e4de";
}
.icon-paypal3:before {
  content: "\e4df";
}
.icon-yelp:before {
  content: "\e4e0";
}
.icon-libreoffice:before {
  content: "\e4e1";
}
.icon-file-pdf:before {
  content: "\e4e2";
}
.icon-file-openoffice:before {
  content: "\e4e3";
}
.icon-file-word:before {
  content: "\e4e4";
}
.icon-file-excel:before {
  content: "\e4e5";
}
.icon-file-zip:before {
  content: "\e4e6";
}
.icon-file-powerpoint:before {
  content: "\e4e7";
}
.icon-file-xml:before {
  content: "\e4e8";
}
.icon-file-css:before {
  content: "\e4e9";
}
.icon-html5:before {
  content: "\e4ea";
}
.icon-html52:before {
  content: "\e4eb";
}
.icon-css3:before {
  content: "\e4ec";
}
.icon-chrome:before {
  content: "\e4ed";
}
.icon-firefox:before {
  content: "\e4ee";
}
.icon-IE:before {
  content: "\e4ef";
}
.icon-opera:before {
  content: "\e4f0";
}
.icon-safari:before {
  content: "\e4f1";
}
.icon-IcoMoon:before {
  content: "\e4f2";
}
